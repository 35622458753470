import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "./baseQuery"
import {
  type ResponseGetTimes,
  type ResponseGetDays,
  type RequestCreateCall,
  type RequestGetTimes,
  type ResponseCalls,
  type RequestUpdateCall
} from "../shared/types/api/calls"
import { type ApiResponseType } from "../shared/types/api/api"

enum Endpoinds {
  Days = "calls/days",
  Times = "calls/times",
  Create = "calls/create",
  Update = "calls/update",
  GetCalls = "calls"
}

export const callApi = createApi({
  reducerPath: "callApi",
  baseQuery,
  tagTypes: ["getCalls"],
  endpoints: (build) => ({
    getCalls: build.query<ResponseCalls[], {}>({
      query: () => ({
        url: Endpoinds.GetCalls
      }),
      providesTags: ["getCalls"],
      transformResponse: (response: ApiResponseType<ResponseCalls[]>) =>
        response.data
    }),
    getDays: build.query<ResponseGetDays[], { timezone: string }>({
      query: ({ timezone }) => ({
        url: Endpoinds.Days,
        params: { timezone }
      }),
      transformResponse: (response: ApiResponseType<ResponseGetDays[]>) =>
        response.data
    }),
    getTimes: build.query<ResponseGetTimes[], RequestGetTimes>({
      query: ({ date, timezone }) => ({
        url: Endpoinds.Times,
        params: {
          date: date && date,
          timezone
        }
      }),
      transformResponse: (response: ApiResponseType<ResponseGetTimes[]>) =>
        response.data
    }),
    createCall: build.mutation<ResponseCalls, RequestCreateCall>({
      query: (body) => ({
        url: Endpoinds.Create,
        body,
        method: "POST"
      }),
      invalidatesTags: () => ["getCalls"],
      transformResponse: (response: ApiResponseType<ResponseCalls>) =>
        response.data
    }),
    updateCall: build.mutation<ResponseCalls, RequestUpdateCall>({
      query: ({ id, start_at: startAt, timezone }) => ({
        url: Endpoinds.Update + `/${id}`,
        body: {
          start_at: startAt,
          timezone
        },
        method: "PUT"
      }),
      invalidatesTags: () => ["getCalls"],
      transformResponse: (response: ApiResponseType<ResponseCalls>) =>
        response.data
    })
  })
})

export const {
  useGetDaysQuery,
  useGetTimesQuery,
  useCreateCallMutation,
  useUpdateCallMutation,
  useGetCallsQuery
} = callApi
