import { t } from "i18next"
import { type IQestionItems } from "../types/types"

export const CreateProjectItems: IQestionItems[] = [
  {
    name: "first",
    title: "",
    answer: [
      { label: t("New_Project"), type: "new" },
      { label: t("Revision_of_an_existing_project"), type: "revision" }
    ]
  },
  {
    name: "second",
    title: t("Which_team_do_you_need"),
    answer: [
      { label: t("Comprehensive_development"), type: "team" },
      { label: t("Strengthening_team"), type: "specialist" }
    ]
  },
  {
    name: "third",
    title: t("Your_experience_in_managing_IT_specialists"),
    answer: [
      { label: t("Sugestion_Create_Project"), type: "experienced" },
      { label: t("Sugestion_Create_Project_2"), type: "professional" },
      { label: t("Sugestion_Create_Project_3"), type: "anything" }
    ]
  }
]

export const CreateProjectItemsAdditional: IQestionItems[] = [
  {
    name: "first",
    title: t("Testing"),
    answer: [
      { label: t("Sugestion_Create_Project_4"), type: "true" },
      { label: t("No_need"), type: "true" }
    ]
  },
  {
    name: "second",
    title: t("Code_Review"),
    answer: [
      { label: t("Sugestion_Create_Project_5"), type: "true" },
      { label: t("No_need"), type: "true" }
    ]
  }
]

export const LKKeys = {
  SettingsView: "SettingsView"
}

export const LANGUAGE = process.env.REACT_APP_LANG ?? "en"
export const LANDING_LINK = process.env.REACT_APP_LANDING_LINK ?? ""
export const USER_AGREEMENTS = `${LANDING_LINK}/${LANGUAGE}/agreements`
