import { combineReducers, configureStore } from "@reduxjs/toolkit"
import authReducer from "./Slices/auth"
import userReducer from "./Slices/user"
import { authApi } from "../api/auth"
import { callApi } from "../api/call"
import { projectCreateApi } from "../api/projectCreate"

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  [authApi.reducerPath]: authApi.reducer,
  [callApi.reducerPath]: callApi.reducer,
  [projectCreateApi.reducerPath]: projectCreateApi.reducer
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    }).concat(
      authApi.middleware,
      callApi.middleware,
      projectCreateApi.middleware
    )
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof store.getState>

export default store
