import { type PayloadAction, createSlice } from "@reduxjs/toolkit"
import { setUser } from "./user"
import { type IUser } from "../../shared/types/types"
import { type AppDispatch } from ".."

const initialState = {
  isAuth: false,
  access_token: "",
  fingerprint: ""
}

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.access_token = action.payload
    },
    setFingerPrint: (state, action: PayloadAction<string>) => {
      state.fingerprint = action.payload
    }
  }
})

const { reducer: authReducer, actions } = AuthSlice

export const { setAuth, setAccessToken, setFingerPrint } = actions

export const signIn =
  (accessToken: string, user: IUser) => async (dispatch: AppDispatch) => {
    dispatch(setAuth(true))
    dispatch(setAccessToken(accessToken))
    dispatch(setUser(user))
  }

export const signOut = () => async (dispatch: AppDispatch) => {
  dispatch(setAuth(false))
  dispatch(setAccessToken(""))
  dispatch(setUser({} as IUser))
}

export default authReducer
