import { type CSSProperties, type FC } from "react"
import RiseLoader from "react-spinners/RiseLoader"
import styles from "./Loader.module.scss"

interface Props {
  load: boolean
  css?: CSSProperties
}

const Loader: FC<Props> = ({ load, css }) => {
  return (
    <div className={styles.container}>
      <RiseLoader
        color="#ff8049"
        loading={load}
        cssOverride={css}
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  )
}

export default Loader
