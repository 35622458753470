import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "./baseQuery"
import {
  type ResponseResendCode,
  type ReqestPnoneType,
  type ResponsePhoneType,
  type RequestResendCode,
  type ResponseVerifyCode,
  type RequestVerifyCode,
  type RequestLogin
  // type RequestTG
} from "../shared/types/api/auth"
import { type ApiResponseType } from "../shared/types/api/api"

enum Endpoinds {
  Login = "auth/login",
  ResendLoginCode = "auth/resend-code",
  Accept = "auth/accept",
  Refresh = "auth/refresh-token",
  Phone = "customer/phone",
  ResendCode = "customer/resend-code",
  VerifyCode = "customer/verify-code",
  Logout = "auth/logout",
  TG = "customer/tg"
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery,
  endpoints: (build) => ({
    login: build.mutation<ResponsePhoneType, RequestLogin>({
      query: (body) => ({
        url: Endpoinds.Login,
        method: "POST",
        body
      }),
      transformResponse: (response: ApiResponseType<ResponsePhoneType>) =>
        response.data
    }),
    resendCodeLogin: build.mutation<ResponseResendCode, RequestResendCode>({
      query: (body) => ({
        url: Endpoinds.ResendLoginCode,
        method: "POST",
        body
      }),
      transformResponse: (response: ApiResponseType<ResponseResendCode>) =>
        response.data
    }),
    accept: build.mutation<ResponseVerifyCode, RequestVerifyCode>({
      query: (body) => ({
        url: Endpoinds.Accept,
        method: "POST",
        body
      }),
      transformResponse: (response: ApiResponseType<ResponseVerifyCode>) =>
        response.data
    }),
    phone: build.mutation<ResponsePhoneType, ReqestPnoneType>({
      query: (body) => ({
        url: Endpoinds.Phone,
        body,
        method: "POST"
      }),
      transformResponse: (response: ApiResponseType<ResponsePhoneType>) =>
        response.data
    }),
    resendCode: build.mutation<ResponseResendCode, RequestResendCode>({
      query: (body) => ({
        url: Endpoinds.ResendCode,
        body,
        method: "POST"
      }),
      transformResponse: (response: ApiResponseType<ResponseResendCode>) =>
        response.data
    }),
    verifyCode: build.mutation<ResponseVerifyCode, RequestVerifyCode>({
      query: (body) => ({
        url: Endpoinds.VerifyCode,
        body,
        method: "POST"
      }),
      transformResponse: (response: ApiResponseType<ResponseVerifyCode>) =>
        response.data
    }),
    sendRefresh: build.mutation<ResponseVerifyCode, { fingerprint: string }>({
      query: (body) => ({
        url: Endpoinds.Refresh,
        body,
        method: "POST"
      }),
      transformResponse: (response: ApiResponseType<ResponseVerifyCode>) =>
        response.data
    }),
    logout: build.mutation<string, {}>({
      query: () => ({
        url: Endpoinds.Logout,
        method: "POST"
      })
    }),
    tg: build.query<string, {}>({
      query: (params) => ({
        url: Endpoinds.TG,
        params
      })
    })
  })
})

export const {
  usePhoneMutation,
  useResendCodeMutation,
  useVerifyCodeMutation,
  useSendRefreshMutation,
  useAcceptMutation,
  useLoginMutation,
  useResendCodeLoginMutation,
  useLogoutMutation
} = authApi
