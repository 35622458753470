import { type FC, Suspense } from "react"
import { Loader } from "../../../shared/ui/Loader"
import { Navigate, Route, Routes } from "react-router-dom"
import { RoutePaths, routeConfig } from "../../../config/routes"

const AppRoutes: FC = () => {
  return (
    <Suspense fallback={<Loader load />}>
      <Routes>
        {Object.values(routeConfig).map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={<div className="page-wrapper">{element}</div>}
          />
        ))}
        <Route path="*" element={<Navigate replace to={RoutePaths.main} />} />
      </Routes>
    </Suspense>
  )
}

export default AppRoutes
