import { useLayoutEffect, type FC, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../hooks/redux"
import AppRoutes from "./AppRoutes"
import AuthRoutes from "./AuthRoutes"
import { useSendRefreshMutation } from "../../../api/auth"
import { loadFingerPrintId } from "../../../shared/config/fingerPrint"
import { setFingerPrint, signIn } from "../../../store/Slices/auth"
import { Loader } from "../../../shared/ui/Loader"

const AppRouter: FC = () => {
  const dispatch = useAppDispatch()
  const [fetchRefresh] = useSendRefreshMutation()
  const { isAuth } = useAppSelector((state) => state.auth)

  const [isLoading, setIsLoading] = useState(true)

  const isLogin = async () => {
    const fp = localStorage.getItem("fp")

    const result = await fetchRefresh({ fingerprint: fp ?? "" })

    if (result && "data" in result) {
      const { access_token: acessToken, user } = result.data
      dispatch(signIn(acessToken, user))
    }

    setIsLoading(false)
  }

  const saveFingerPrint = async () => {
    const fp = localStorage.getItem("fp")

    if (!fp) {
      const fingerPrint = await loadFingerPrintId()
      localStorage.setItem("fp", fingerPrint)
      dispatch(setFingerPrint(fingerPrint))
    } else {
      dispatch(setFingerPrint(fp))
    }
  }

  useLayoutEffect(() => {
    saveFingerPrint()
    isLogin()
  }, [])

  if (isLoading) {
    return <Loader load={isLoading} />
  }

  if (!isAuth) {
    return <AuthRoutes />
  } else {
    return <AppRoutes />
  }
}

export default AppRouter
