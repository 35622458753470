import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "./baseQuery"
import {
  type RequestAdditionaly,
  type ResponseCreate,
  type RequestCreate,
  type ResponseProjects
} from "../shared/types/api/createProject"
import { type ApiResponseType } from "../shared/types/api/api"

enum Endpoinds {
  GetProjects = "projects",
  Create = "projects",
  CreateAdditionaly = "projects/add"
}

export const projectCreateApi = createApi({
  reducerPath: "projectCreateApi",
  baseQuery,
  tagTypes: ["getProject"],
  endpoints: (build) => ({
    getProjects: build.query<ResponseProjects[], {}>({
      query: () => ({
        url: Endpoinds.GetProjects
      }),
      providesTags: ["getProject"],
      transformResponse: (response: ApiResponseType<ResponseProjects[]>) =>
        response.data
    }),
    create: build.mutation<ResponseCreate, RequestCreate>({
      query: (body) => ({
        url: Endpoinds.Create,
        method: "POST",
        body
      }),
      invalidatesTags: () => ["getProject"],
      transformResponse: (response: ApiResponseType<ResponseCreate>) =>
        response.data
    }),
    additionaly: build.mutation<ResponseCreate, RequestAdditionaly>({
      query: ({ id, code_review: CodeReview, testing }) => ({
        url: Endpoinds.CreateAdditionaly + `/${String(id)}`,
        method: "PUT",
        body: { code_review: CodeReview, testing }
      }),
      invalidatesTags: () => ["getProject"],
      transformResponse: (response: ApiResponseType<ResponseCreate>) =>
        response.data
    })
  })
})

export const {
  useAdditionalyMutation,
  useCreateMutation,
  useGetProjectsQuery
} = projectCreateApi
