import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./app/App"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./config/i18n"
import { Suspense } from "react"
import { Loader } from "./shared/ui/Loader"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <Suspense fallback={<Loader load />}>
    <BrowserRouter>
      <App />
      <ToastContainer />
    </BrowserRouter>
  </Suspense>
)
