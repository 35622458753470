import { type PayloadAction, createSlice } from "@reduxjs/toolkit"
import { type IUser } from "../../shared/types/types"

const initialState = {
  user: {} as IUser,
  settings: {
    dateCall: null as null | Date,
    callId: undefined as number | undefined // Используется при регистрации, для того, чтобы определить, обновлять время или создавать новое
  },
  skip: false,
  afterReg: false
}

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload
    },
    setDateCall: (state, action: PayloadAction<Date>) => {
      state.settings.dateCall = action.payload
    },
    setCallId: (state, action: PayloadAction<number>) => {
      state.settings.callId = action.payload
    },
    setSkip: (state, action: PayloadAction<boolean>) => {
      state.skip = action.payload
    },
    setAfterReg: (state, action: PayloadAction<boolean>) => {
      state.afterReg = action.payload
    }
  }
})

const { reducer: userReducer, actions } = UserSlice
export const { setUser, setDateCall, setCallId, setSkip, setAfterReg } = actions

export default userReducer
