import { type RouteProps } from "react-router-dom"
import { SignUpAsync } from "../../pages/Auth/Registration"
import { MainAsync } from "../../pages/Main"
import { UserAgreementsAsync } from "../../pages/UserAgreements"
import { TimeToCallAsync } from "../../pages/TimeToCall"
import { CreateProjectAsync } from "../../pages/CreateProject"
import { AdditionalControllAsync } from "../../pages/AdditioanalControll"
import { LoginAsync } from "../../pages/Auth/Login"
import { FinishREgisterAsync } from "../../pages/FinishRegister"

export enum AppRoutes {
  Main = "main",
  TimeToCall = "time",
  CreateProject = "project",
  AdditionalControll = "additional",
  FinishRegistr = "finish"
}

export enum AuthRoutes {
  Auth = "auth",
  UserAgreements = "agreements",
  Login = "login"
}

export const AuthPaths: Record<AuthRoutes, string> = {
  [AuthRoutes.Auth]: "/auth",
  [AuthRoutes.UserAgreements]: "/agreements",
  [AuthRoutes.Login]: "/login"
}

export const RoutePaths: Record<AppRoutes, string> = {
  [AppRoutes.Main]: "/",
  [AppRoutes.TimeToCall]: "/time",
  [AppRoutes.CreateProject]: "/project",
  [AppRoutes.AdditionalControll]: "/additional",
  [AppRoutes.FinishRegistr]: "/finish"
}

export const routeConfigAuth: Record<AuthRoutes, RouteProps> = {
  [AuthRoutes.Auth]: {
    path: AuthPaths.auth,
    element: <SignUpAsync />
  },
  [AuthRoutes.UserAgreements]: {
    path: AuthPaths.agreements,
    element: <UserAgreementsAsync />
  },

  [AuthRoutes.Login]: {
    path: AuthPaths.login,
    element: <LoginAsync />
  }
}

export const routeConfig: Record<AppRoutes, RouteProps> = {
  [AppRoutes.TimeToCall]: {
    path: RoutePaths.time,
    element: <TimeToCallAsync />
  },
  [AppRoutes.CreateProject]: {
    path: RoutePaths.project,
    element: <CreateProjectAsync />
  },
  [AppRoutes.AdditionalControll]: {
    path: RoutePaths.additional,
    element: <AdditionalControllAsync />
  },
  [AppRoutes.FinishRegistr]: {
    path: RoutePaths.finish,
    element: <FinishREgisterAsync />
  },
  [AppRoutes.Main]: {
    path: RoutePaths.main,
    element: <MainAsync />
  }
}
