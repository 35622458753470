import { Suspense } from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import AppRouter from "./providers/AppRouter"
import "./styles/index.scss"
import { Provider } from "react-redux"
import store from "../store"
import { LANGUAGE } from "../shared/constants/constants"

function App() {
  const { t } = useTranslation()
  return (
    <Suspense fallback="">
      <Provider store={store}>
          <Helmet>
              <meta name="description" content={t("meta_description_default")} />
          </Helmet>
          {LANGUAGE === "ru" ? (
              <Helmet>
                  <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-WXCNC98D');`}</script>
                  <script type="text/javascript">{`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                          m[i].l=1*new Date();
                          for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
                          k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

                          ym(98317129, "init", {
                          clickmap:true,
                          trackLinks:true,
                          accurateTrackBounce:true,
                          webvisor:true
                      });`}</script>
              </Helmet>
          ) : (
              <Helmet>
                  <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-5SZ9LCQG');`}</script>
                  <script type="text/javascript">{`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                          m[i].l=1*new Date();
                          for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
                          k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

                          ym(94781607, "init", {
                          clickmap:true,
                          trackLinks:true,
                          accurateTrackBounce:true,
                          webvisor:true
                      });`}</script>
              </Helmet>
          )}
          <script type="text/javascript">{`window.dataLayer = window.dataLayer || [];`}</script>
          <noscript>
              <iframe
                  src={`https://www.googletagmanager.com/ns.html?id=${LANGUAGE === "ru" ? "GTM-WXCNC98D" : "GTM-5SZ9LCQG"}`}
                  height="0"
                  width="0"
                  style={ { display: "none", visibility: "hidden" } }
              />
          </noscript>
        <AppRouter />
      </Provider>
    </Suspense>
  )
}

export default App
