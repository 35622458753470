import { type RootState } from "./../store/index"
import {
  type BaseQueryApi,
  type FetchArgs,
  fetchBaseQuery
} from "@reduxjs/toolkit/dist/query/react"
import { setAccessToken } from "../store/Slices/auth"
import { type ResponseRefresh } from "../shared/types/api/auth"

interface ResponseRefreshType {
  data: ResponseRefresh
}

const endpoint = "auth/refresh-token"

const baseQueryWithToken = fetchBaseQuery({
  baseUrl: "/api/",
  prepareHeaders: (headers, { getState }) => {
    const accessToken = (getState() as RootState).auth.access_token
    headers.set("Accept", "application/json")

    if (accessToken?.length > 0) {
      headers.set("Authorization", `Bearer ${accessToken}`)
    }

    return headers
  }
})

export const baseQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  const result = await baseQueryWithToken(args, api, extraOptions)
  const { isAuth } = (api.getState() as RootState).auth
  const fingerprint = localStorage.getItem("fp")

  if (result.error?.status === 401 && isAuth) {
    const refreshResult = (await baseQueryWithToken(
      {
        url: endpoint,
        body: {
          fingerprint
        },
        method: "POST"
      },
      api,
      extraOptions
    )) as ResponseRefreshType

    if (refreshResult?.data) {
      const { data } = refreshResult

      if (data.access_token) {
        api.dispatch(setAccessToken(data.access_token))
      }
    }
  }

  return result
}
